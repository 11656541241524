import algoliasearch from 'algoliasearch/lite';
import React from 'react';
import 'twin.macro';
import SearchItem, { SearchItemProps } from './search-item';
import { PageContext, Pagination } from './pagination';

interface IndexDoc {
  id?: string;
  uid?: string;
  title?: string;
  category?: string;
  tags?: string;
  author?: string;
  info?: string;
  date?: string;
  shortDescription?: {
    raw?: string;
  };
  description?: {
    raw?: string;
  };
  recommendation?: {
    raw?: string;
  };
  summary?: {
    raw?: string;
  };
  image?: {
    file?: {
      url: string;
    };
  };
}

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);
const searchIndex = searchClient.initIndex('article_post');

interface SearchProps {
  query?: string;
  page?: number;
  style?: React.CSSProperties;
  className?: string;
}

interface SearchState {
  hits?: SearchItemProps[];
  processingTimeMs?: number;
  query?: string;
  page?: number;
  pageCount?: number;
  totalCount?: number;
}

class Search extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);
    this.state = {
      processingTimeMs: 0,
      query: props.query,
      page: props.page,
      pageCount: 0,
      hits: [],
    };
  }

  async componentDidMount() {
    this.searchArticle();
  }

  componentDidUpdate(prevProps: SearchProps) {
    if (prevProps.query !== this.props.query || prevProps.page !== this.props.page) {
      this.setState({
        query: this.props.query,
        page: this.props.page,
      });
      this.searchArticle();
    }
  }

  async searchArticle() {
    try {
      const resp = await searchIndex.search<IndexDoc>(this.props?.query, {
        page: this.props.page - 1,
        hitsPerPage: 5,
        attributesToHighlight: ['title', 'category', 'shortDescription'],
        highlightPreTag: '-::',
        highlightPostTag: '::-',
      });

      // map search results
      this.setState({
        hits: resp.hits.map(hit => ({
          id: hit.id,
          category: hit._highlightResult.category.value,
          date: hit.date,
          image: hit.image.file.url,
          info: hit.info,
          description: hit._highlightResult.shortDescription.raw.value,
          link: '/' + hit.uid,
          title: hit._highlightResult.title.value,
        })),
        pageCount: resp.nbPages,
        processingTimeMs: resp.processingTimeMS,
        totalCount: resp.nbHits,
      });
    } catch (err) {}
  }

  render() {
    const query = this.state.query;
    const articles = this.state.hits;

    const hasNextPage = this.state.page < this.state.pageCount;
    const hasPreviousPage = this.state.pageCount > 1 && this.state.page > 1;

    const pageContext: PageContext = {
      currentPage: this.state.page,
      hasNextPage,
      hasPreviousPage,
      pageCount: this.state.pageCount,
      perPage: 5,
      totalCount: this.state.totalCount,
    };

    return (
      <div className={this.props.className} style={this.props.style}>
        <div tw="my-8 dark:text-white">Menampilkan hasil pencarian "{query}"</div>
        <div tw="mt-4">
          {articles.map((article, idx) => (
            <div key={'article-result-' + idx}>
              <SearchItem key={article.link} {...article} />
            </div>
          ))}
        </div>

        <Pagination
          pageContext={pageContext}
          route={'search'}
          pageRoute={`?query=${query}`}
          customPageRoute={'&page='}
        ></Pagination>
      </div>
    );
  }
}

export default Search;
