import React, { FormEvent, ChangeEvent } from 'react';
import 'twin.macro';
import { css } from '@emotion/react';

interface SearchFormState {
  query?: string;
}

interface SearchFormProps {
  query?: string;
  onSubmit?: Function;
  style?: React.CSSProperties;
  className?: string;
}

class SearchForm extends React.Component<SearchFormProps, SearchFormState> {
  constructor(props: SearchFormProps) {
    super(props);
    this.state = { query: props.query };
  }

  componentDidUpdate(prevProps: SearchFormProps) {
    if (prevProps.query !== this.props.query) {
      this.setState({
        query: this.props.query,
      });
    }
  }

  handleSearch(event: FormEvent) {
    event.preventDefault();
    this.props.onSubmit(this.state.query);
  }

  handleQueryChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      query: event.target?.value,
    });
  }

  render() {
    return (
      <form tw="flex" onSubmit={e => this.handleSearch(e)}>
        <div
          tw="rounded-full bg-white pl-4 flex items-center"
          css={css`
            border: 1px solid #ecf2f8;
            min-width: 260px;
            @media (max-width: 1024px) {
              min-width: 200px;
            }
            input {
              &:focus {
                outline: none;
              }
            }
          `}
        >
          <span className="icon icon-search"></span>
          <input
            type="search"
            tw="h-full border-0 ml-1 bg-transparent"
            placeholder="Cari judul artikel atau tag..."
            value={this.state?.query}
            onChange={e => this.handleQueryChange(e)}
            css={css`
              width: 95%;
            `}
          />
        </div>
        <button
          tw="text-center bg-yellow-bright hover:bg-khaki active:bg-golden border-0 text-blue-marine font-semibold py-3 px-6 rounded-full cursor-pointer no-underline inline-block dark:border-black-300 px-1 ml-3"
          type="submit"
        >
          <div tw="flex items-center justify-center px-4">
            <span>Cari</span>
          </div>
        </button>
      </form>
    );
  }
}

export default SearchForm;
