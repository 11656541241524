import React from 'react';
import 'twin.macro';
import { PageProps, navigate, graphql } from 'gatsby';
import queryString from 'query-string';
import BaseLayout from '../layouts/base';
import SearchResult from '../components/search-result';
import SearchForm from '../components/search-form';
import { GetSearchDataQuery } from '../graphql-types';
import { Helmet } from 'react-helmet';

interface SearchState {
  query?: string;
  page?: number;
}

class Search extends React.Component<PageProps<GetSearchDataQuery>, SearchState> {
  constructor(props: PageProps) {
    super(props);
    this.state = { query: '', page: 1 };
  }

  componentDidMount() {
    this.updateSearchParams(this.props.location.search);
  }

  updateSearchParams(search: string) {
    const params = queryString.parse(search);
    const query = (params['query'] as string) ?? '';
    const page = parseInt((params['page'] as string) ?? '1', 10);
    this.setState({
      query,
      page,
    });
  }

  componentDidUpdate(prevProps: PageProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateSearchParams(this.props.location.search);
    }
  }

  handleSearch(query: string) {
    navigate(`/search/?query=${query ?? ''}`);
  }

  render() {
    const logo = this.props.data?.logo?.childrenImageSharp[0]?.fluid;

    // SEO data
    const siteMeta = this.props.data.site?.siteMetadata;
    const canonical = `${siteMeta.url}/artikel`;

    const jsonLd = {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebSite',
          '@id': 'https://www.easybiz.id/#website',
          url: 'https://www.easybiz.id/',
          name: 'Easybiz',
          description: 'Easybiz.id',
          potentialAction: [
            {
              '@type': 'SearchAction',
              target: 'https://www.easybiz.id/?s={search_term_string}',
              'query-input': 'required name=search_term_string',
            },
          ],
          inLanguage: 'en-US',
        },
        {
          '@type': 'WebPage',
          '@id': 'https://www.easybiz.id/search/#webpage',
          url: 'https://www.easybiz.id/search/',
          name: 'Article - Easybiz',
          isPartOf: { '@id': 'https://www.easybiz.id/#website' },
          datePublished: '2020-09-05T05:42:40+00:00',
          dateModified: '2021-02-25T08:36:09+00:00',
          inLanguage: 'en-US',
          potentialAction: [
            {
              '@type': 'ReadAction',
              target: ['https://www.easybiz.id/search/'],
            },
          ],
        },
      ],
    };

    return (
      <BaseLayout logo={logo} pathname={this.props.location.pathname} fixedHeader={true}>
        <Helmet>
          <title>Pencarian Artikel - Easybiz</title>
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href={canonical} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Article - Easybiz" />
          <meta property="og:url" content="https://www.easybiz.id/search/" />
          <meta property="og:site_name" content="Easybiz" />
          <meta property="article:modified_time" content="2021-02-25T08:36:09+00:00" />
          <meta name="twitter:card" content="summary_large_image" />
          <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
        </Helmet>
        <section tw="bg-white dark:bg-black-200">
          <div tw="container">
            <div tw="mx-8 lg:mx-32 mt-28 lg:mt-16">
              <div tw="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 py-4">
                <div tw="lg:col-span-2">
                  <SearchForm
                    query={this.state?.query}
                    onSubmit={query => this.handleSearch(query)}
                  />
                  <SearchResult
                    tw="lg:col-span-2"
                    query={this.state?.query}
                    page={this.state?.page}
                  ></SearchResult>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default Search;

export const query = graphql`
  query getSearchData {
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
